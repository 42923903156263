import React from "react";
import comingSoon from "../assets/ComingSoon.png";
import insta from "../assets/instagram.png"

function handleContactUsClick(){
window.location = "mailto:support@denim4all.com"
}

function handleInstaClick(){
    window.location = "https://www.instagram.com/denim4allofficial/";
}
const ComingSoon = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className=" flex flex-col justify-center items-center lg:flex-row">
        <img src={comingSoon} className="w-screen md:w-auto md:h-auto"></img>
        <div className="flex flex-col font-league-spartan justify-center">
          <p className="lg:text-9xl text-5xl">Launching</p>
          <p className="lg:text-9xl text-5xl">Soon</p>
          <button
            className="w-36 h-9 text-center bg-black text-white border-4 border-yellow-300"
            onClick={handleContactUsClick}
            >
            CONTACT US
          </button>

          <div className="flex my-10 items-center">
            <p> GET SOCIAL</p>
            <ul>
              <img src={insta} onClick={handleInstaClick} className="w-6 h-6 mx-5 cursor-pointer"></img>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
