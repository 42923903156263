import "react-multi-carousel/lib/styles.css";
import ComingSoon from "./pages/ComingSoon";



export default function App() {


  
  return (
    <>
     <ComingSoon></ComingSoon>
    </>
  );
}
